import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const handleDocOpen = () => {
    // window.open("/Terms.docx");
    window.open("/Termsnew.pdf", "_blank");
  };
  const handleDocOpen2 = () => {
    window.open("/Privacynew.pdf", "_blank");
  };
  const handleDocOpen3 = () => {
    window.open("/salesnew.pdf", "_blank");
  };
  return (
    <footer className="footer_main">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="aboutfooter">
              <Link to={"/"}>
                <img src="/assets/img/footerlogo.png" alt="i" />
              </Link>
              <p>
                {t("Dress Up in Style with Boutiquei")} <br />
                {t("Available on Play Store and App Store")}
              </p>
              <div className="details_box mb-3">
                <span>
                  <img src="/assets/img/Message_light.png" alt="i" />
                </span>{" "}
                <a href="mailto:info@todayfashion.com">admin@boutiquei.com</a>
              </div>
              <div className="details_box">
                <span>
                  <img src="/assets/img/Phone.png" alt="i" />
                </span>{" "}
                <a href="tel:+1800-222-333">+971 561 988 997</a>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="footer_links">
              <h2>{t("quick links")}</h2>
              <div className="linkmain">
                <Link to={"/"}>{t("home")}</Link>
                <Link to={"/about-us"} onClick={window.scrollTo(0, 0)}>
                  {t("about us")}
                </Link>
                <Link to={"/contact-us"} onClick={window.scrollTo(0, 0)}>
                  {t("contact")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-md-center">
            <div className="footer_links">
              <h2>{t("Important links")}</h2>
              <div className="linkmain">
                <Link
                  to={"#"}
                  onClick={handleDocOpen}
                  style={{ textTransform: "none" }}
                >
                  {t("Terms of Use")}
                </Link>
              </div>
              <div className="linkmain">
                <Link to={"#"} onClick={handleDocOpen2}>
                  {t("Privacy Policy")}
                </Link>
              </div>
              <div className="linkmain">
                <Link
                  to={"#"}
                  onClick={handleDocOpen3}
                  style={{ textTransform: "none" }}
                >
                  {t("Terms of Sale")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer_links">
              <h2 className="ms-lg-5 mb-0 mb-lg-3 text-start">
                {t("Social Media")}
              </h2>
              <div className="d-flex align-items-center justify-content-md-start w-100 gap-md-0 gap-2 ms-lg-5 ms-md-3">
                <Link to={"#"} className="social_iconss mx-1">
                  <img src="resources/twitter.svg" alt="i" className="mt-3" />
                </Link>
                <Link to={"#"} className=" social_iconss mx-1">
                  <img src="resources/facebook.svg" alt="i" className="mt-3" />
                </Link>
                <Link to={"#"} className=" social_iconss mx-1">
                  <img src="resources/youtube.svg" alt="i" className="mt-3" />
                </Link>
                <Link to={"#"} className=" social_iconss mx-1">
                  <img src="resources/instagram.svg" alt="i" className="mt-3" />
                </Link>
              </div>
            </div>

            <div className="appdownload">
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Coming Soon"
                href="javascript:void(0)"
              >
                <img src="resources/appstore.svg" alt />
              </a>
              <a
                className="play-button"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Coming Soon"
                href="javascript:void(0)"
              >
                <img src="resources/google-play.svg" alt />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
