import React from "react";
import Layout from "./Layout/Layout";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaGooglePlay, FaAppStore } from "react-icons/fa";

const Home = () => {
  const { t, i18n } = useTranslation();

  const handleLinkClick = (e) => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 768) {
      e.preventDefault();
      Swal.fire({
        imageUrl: "/assets/img/sorry2.png",
        imageWidth: 100,
        // icon: "info",
        // title: "Sorry!",
        text: t(
          "For a better user experience, our Merchant panel is available to use on bigger screens like Laptop and Desktops. Thanks."
        ),
        confirmButtonText: t("OK"),
      });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Boutique </title>
        <link rel="canonical" to="http://mysite.com/example" />
      </Helmet>

      <Layout active={"Home"}>
        <section
          className="banner_part"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner_content">
                  <span>{t("Shop Now & Get")} </span>
                  <h1>{t("50% Off!")}</h1>
                  <p>{t("Exclusive Deals Await.")}</p>
                  <div className="d-flex">
                    <Link className="bg_tn me-3" to="#">
                      {t("Download Now!")}
                    </Link>
                    {/* <Link className="nonbg_tn" to="/about-us">
                      {t("Learn More")}
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tagline">
          <div className="container">
            <img src="/assets/img/partner.png" alt />
          </div>
        </section>
        <section className="similarsection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img className="similar_img" src="assets/img/about.png" alt />
              </div>
              <div className="col-md-6">
                <div className="similar_content">
                  <h2 className="mb-3">{t("Why Wait? Shop on the Go")} </h2>
                  <h2 className="mb-3"> {t("with Our App!")} </h2>
                  <p>
                    {t(
                      "Experience the convenience of shopping at your fingertips!"
                    )}
                  </p>
                  <p>
                    {t(
                      "Download our app and enjoy a seamless shopping experience with exclusive deals, faster checkout, and early access to new collections."
                    )}
                  </p>
                  <p>
                    {t(
                      "Plus, as a special welcome, we're offering you 50% off your first purchase through the app."
                    )}
                  </p>
                  <p>
                    {t(
                      "Don't miss out on these incredible savings—download the app now and start shopping smarter!"
                    )}
                  </p>
                 <div className="play_btn">
                 <Link className="nonbg2_tn" to="/about-us">
                    <FaGooglePlay style={{ marginRight: "8px" }} />
                    {t("Play Store")}
                  </Link>
                  <Link className="nonbg2_tn" to="/about-us">
                    <FaAppStore style={{ marginRight: "8px" }} />
                    {t("App Store")}
                  </Link>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5 col-md-12 ">
          <div className="areyoumerchant text-center">
            <h2>{t("Are you a merchant?")}</h2>
          </div>
        </section>
        <section className="why_boutiquei comman_space">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="comman_head text-center">
                  <h2>{t("Why Boutiquei is Perfect for you")}</h2>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="whyboutiquei_box text-center">
                  <span>1</span>
                  <h3>{t("TARGETED AUDIENCE")}</h3>
                  <p>
                    {t(
                      "Reach a curated community of fashion-forward customers actively searching for unique and stylish clothing,accessories, and more."
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="whyboutiquei_box text-center">
                  <span>2</span>
                  <h3>{t("STREAMLINED OPERATIONS")}</h3>
                  <p>
                    {t(
                      "Manage your inventory, orders, and customer communication all in one place."
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="whyboutiquei_box text-center">
                  <span>3</span>
                  <h3>{t("INCREASED VISIBILITY")}</h3>
                  <p>
                    {t(
                      "Gain exposure to a wider customer base through Boutiquei's extensive marketing efforts."
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whyboutiquei_box text-center">
                  <span>4</span>
                  <h3>{t("EFFORTLESS ONLINE PRESENCE")}</h3>
                  <p>
                    {t(
                      "List your products on a user-friendly platform, eliminating the need for you to manage your own online store."
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whyboutiquei_box text-center">
                  <span>5</span>
                  <h3>{t("Grow Your Brand")}</h3>
                  <p>
                    {" "}
                    {t(
                      "Gain valuable insights into customer preferences and buying trends to inform your future collections."
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whyboutiquei_box text-center">
                  <span>6</span>
                  <h3>{t("SEAMLESS FULFILLMET")}</h3>
                  <p>
                    {t(
                      "We handle secure payment processing and reliable order fulfillment, allowing you to focus on what you do best creating amazing products"
                    )}
                  </p>
                  <Link to="/about-us">{t("read more")}</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="similarsection marketplace">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="similar_content">
                  <h2>
                    {t("Boutiquei is More Than Just an Online Marketplace:")}
                  </h2>
                  <div className="marketplace_box mb-5">
                    <h3>{t("COMMUNITY OF BOUTIQUES")}</h3>
                    <p>
                      {t(
                        "Connect and collaborate with other like-minded boutique owners to share ideas and experiences"
                      )}
                    </p>
                  </div>
                  <div className="marketplace_box">
                    <h3>{t("DEDICATED SUPPORT")}</h3>
                    <p>
                      {t(
                        "Our team is here to provide you with ongoing support and guidance"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5" />
            </div>
          </div>
          <img className="similar_img" src="assets/img/marketplace.png" alt />
        </section>
        <section className="why_boutiquei comman_space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="comman_head text-center">
                  <h2>{t("Ready to Join the Boutiquei Family?")}</h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whyboutiquei_box text-center">
                  <span>1</span>
                  <p>
                    {t(
                      "Signing up is easy! Simply visit our website and complete a quick registration process"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="whyboutiquei_box text-center">
                  <span>2</span>
                  <p>
                    {t(
                      "Once approved, you'll gain access to our user-friendly platform and start listing your fabulous products in no time."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Packages">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="comman_head text-center">
                  <h2>{t("Boutiquei Packages")}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="Packages_main">
            <div className="container">
              <div className="Packages_table table-responsive">
                <table className="table mb-0 ">
                  <thead>
                    <tr>
                      <th scope="col">{t("Benefits")}</th>
                      <th scope="col">{t("Platinum package AED 1999")}</th>
                      <th scope="col">{t("Golden package AED 1499")}</th>
                      <th scope="col">{t("Silver package AED 999")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Posting items limit per month")}</td>
                      <td>
                        <div className="tablenumber">✓ 100</div>
                      </td>
                      <td>
                        <div className="tablenumber">✓ 70</div>
                      </td>
                      <td>
                        <div className="tablenumber">✓ 50</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Suggesting the Merchant in the Advertisement rotating banner part in the home screen"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check1"
                              name="check1"
                            />
                            <label htmlFor="check1" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check2"
                              name="check2"
                            />
                            <label htmlFor="check2" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              id="check3"
                              name="check3"
                            />
                            <label htmlFor="check3" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Showing that Merchant at the top of the search page"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check4"
                              name="check4"
                            />
                            <label htmlFor="check4" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check5"
                              name="check5"
                            />
                            <label htmlFor="check5" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              id="check6"
                              name="check6"
                            />
                            <label htmlFor="check6" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          'Suggesting the Merchant in the "Top Boutiques" section on the Home Page'
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check7"
                              name="check7"
                            />
                            <label htmlFor="check7" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check8"
                              name="check8"
                            />
                            <label htmlFor="check8" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              id="check9"
                              name="check9"
                            />
                            <label htmlFor="check9" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Showing that Merchant's items in the You may like part on the home screen"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check10"
                              name="check10"
                            />
                            <label htmlFor="check10" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check11"
                              name="check11"
                            />
                            <label htmlFor="check11" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              id="check12"
                              name="check12"
                            />
                            <label htmlFor="check12" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Online payments")}</td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check13"
                              name="check13"
                            />
                            <label htmlFor="check13" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check14"
                              name="check14"
                            />
                            <label htmlFor="check14" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check15"
                              name="check15"
                            />
                            <label htmlFor="check15" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("Online tracking for their shipments/delivery")}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check16"
                              name="check16"
                            />
                            <label htmlFor="check16" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check17"
                              name="check17"
                            />
                            <label htmlFor="check17" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check18"
                              name="check18"
                            />
                            <label htmlFor="check18" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Rating statistics for items")}</td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check19"
                              name="check19"
                            />
                            <label htmlFor="check19" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check20"
                              name="check20"
                            />
                            <label htmlFor="check20" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check21"
                              name="check21"
                            />
                            <label htmlFor="check21" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Dashboards of the sales on a monthly and yearly basis"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check01"
                              name="check01"
                            />
                            <label htmlFor="check01" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check02"
                              name="check02"
                            />
                            <label htmlFor="check02" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check03"
                              name="check03"
                            />
                            <label htmlFor="check03" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Detailed statistics about that merchant's customers(age, emirate, etc.)"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check04"
                              name="check04"
                            />
                            <label htmlFor="check04" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check5"
                              name="check5"
                            />
                            <label htmlFor="check5" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check6"
                              name="check6"
                            />
                            <label htmlFor="check6" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Identifying the best-selling items for each Merchant based on system statistics"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check07"
                              name="check07"
                            />
                            <label htmlFor="check07" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check08"
                              name="check08"
                            />
                            <label htmlFor="check08" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check09"
                              name="check09"
                            />
                            <label htmlFor="check09" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("Saving the cost of marketing for that Merchant")}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check010"
                              name="check010"
                            />
                            <label htmlFor="check010" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id={""}
                              name={""}
                            />
                            <label htmlFor={""} />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check012"
                              name="check012"
                            />
                            <label htmlFor="check012" />
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t(
                          "Customer Satisfaction rating reports (Items and Merchant)"
                        )}
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check013"
                              name="check013"
                            />
                            <label htmlFor="check013" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check014"
                              name="check014"
                            />
                            <label htmlFor="check014" />
                          </div>
                        </form>
                      </td>
                      <td>
                        <form className="package_check" action="#">
                          <div className="form-group">
                            <input
                              className="d-none"
                              type="checkbox"
                              disabled
                              defaultChecked
                              id="check015"
                              name="check015"
                            />
                            <label htmlFor="check015" />
                          </div>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section className="ctasection">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="cta_content">
                  <img src="/assets/img/signup_logo.png" alt />
                  <p>
                    {t(
                      "Don't miss this exciting opportunity to elevate your boutique brand"
                    )}{" "}
                    &amp; {t("reach a whole new level of success!")}
                  </p>
                  <Link
                    className="signuphover"
                    to="https://merchant.boutiquei.com/"
                    target="_blank"
                    onClick={handleLinkClick}
                  >
                    {t("Sign Up Today and Start Selling on Boutiquei!")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Home;
