import React from "react";
import Layout from "./Layout/Layout";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useContactUsMutation } from "../services/Post";
import { Spinner } from "react-bootstrap";

const Contact = () => {
  const { t } = useTranslation();

  const [contactForm, { isLoading: loading }] = useContactUsMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (info) => {
    const allData = {
      name: info?.firstName,
      subject: info?.subject,
      email: info?.email,
      message: info?.description,
    };
    const res = await contactForm(allData);
    if (res?.data?.error) {
      toast.error(res?.data?.message);
    } else {
      await Swal.fire({
        title: "Success",
        text: res?.data?.message,
        icon: "success",
        confirmButtonText: t("OK"),
      }).then((result) => {
        if (result.isConfirmed) {
          reset();
        }
      });
    }

    console.log("res", res);
  };
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Boutique | Contact Us")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <>
        <section className="contact_banner_part">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
        </section>
        <div className="contact_section">
          <div className="about section lm-seaction-are ">
            <div className="container">
              <div className="row mt-3">
                <div className="col-lg-12  gap-2">
                  <div className="row mt-5">
                    <div className="col-lg-4 col-md-6">
                      <div className=" card ">
                        <h4 className="text-center mt-3">{t("Contact Us")}</h4>
                        <div className="contactus p-3">
                          <div className="d-flex gap-3 mt-3 ">
                            <img
                              src="resources/email.svg"
                              alt="i"
                              srcSet
                              className="mt-3"
                            />
                            <p className="mt-3">admin@boutiquei.com</p>
                          </div>
                          <div className="d-flex gap-3 ">
                            <img
                              src="resources/phone.svg"
                              alt="i"
                              className="mt-3"
                            />
                            <p className="mt-3">+971 561 988 997</p>
                          </div>
                          <h6 className="text-center mt-2">
                            {t("Social Media")}
                          </h6>
                          <div className="social_icons d-flex gap-3 mt-3 ">
                            <img
                              src="resources/twitter.svg"
                              alt="i"
                              className="mt-3"
                            />
                            <p className="mt-3">info@example.com</p>
                          </div>
                          <div className=" social_icons d-flex gap-3 ">
                            <img
                              src="resources/facebook.svg"
                              alt="i"
                              className="mt-3"
                            />
                            <p className="mt-3">info@example.com</p>
                          </div>
                          <div className=" social_icons d-flex gap-3  ">
                            <img
                              src="resources/youtube.svg"
                              alt="i"
                              className="mt-3"
                            />
                            <p className="mt-3">info@example.com</p>
                          </div>
                          <div className=" social_icons d-flex gap-3 ">
                            <img
                              src="resources/instagram.svg"
                              alt="i"
                              className="mt-3"
                            />
                            <p className="mt-3">info@example.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 align-self-center mt-3  ">
                      <div className="contact_section_details ml-3">
                        <h4>{t("Keep in touch")}</h4>
                        <p>
                          {t(
                            "We would love to hear from you and answer any questions you may have. You can contact us by filling out the form below, sending us an email, or calling us on our phone number. We will get back to you as soon as possible. You can also follow us on our social media platforms and subscribe to our newsletter to stay updated on our latest news and offers. Thank you for your interest and support."
                          )}
                        </p>
                        <form action="" onSubmit={handleSubmit(onSubmit)}>
                          <div className="row ">
                            <div className="col-12 col-lg-6 ">
                              <input
                                type="text"
                                className="form-control  "
                                placeholder={t("Name*")}
                                aria-label="First name"
                                {...register("firstName", {
                                  required: t("Name is required*"),
                                  // pattern: {
                                  //   value: /^[\p{L} ]+$/u,
                                  //   message: t(
                                  //     "Name must contain only letters and should not start or end with white space"
                                  //   ),
                                  // },
                                })}
                              />
                              {errors.firstName && (
                                <small className="errorText mx-0 fw-bold">
                                  {errors.firstName?.message}
                                </small>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Email")}
                                aria-label="Last name"
                                {...register("email", {
                                  required: t("Email is required*"),
                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: t("Invalid email address"),
                                  },
                                })}
                              />
                              {errors.email && (
                                <small className="errorText mx-0 fw-bold">
                                  {errors.email?.message}
                                </small>
                              )}
                            </div>
                            <div className="col-12 mt-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("Subject")}
                                aria-label="Subject"
                                {...register("subject", {
                                  required: t("Subject is required*"),
                                  // pattern: {
                                  //   value: /^[\p{L} ]+$/u,
                                  //   message: t("Invalid subject"),
                                  // },
                                })}
                              />
                              {errors.subject && (
                                <small className="errorText mx-0 fw-bold">
                                  {errors.subject?.message}
                                </small>
                              )}
                            </div>
                            <div className="col-12  mt-4">
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                placeholder={t(
                                  "Please enter a brief description..."
                                )}
                                defaultValue={""}
                                {...register("description", {
                                  required: t("Description is required*"),
                                })}
                              />
                              {errors.description && (
                                <small className="errorText mx-0 fw-bold">
                                  {errors.description?.message}
                                </small>
                              )}
                            </div>
                            <div className="col-12 mt-4">
                              {/* <button className="btn btn-light" type="submit">
                                {t("submit")}
                              </button> */}
                              <button
                                type={loading ? "button" : "submit"}
                                disabled={loading}
                                style={{
                                  cursor: loading ? "not-allowed" : "pointer",
                                }}
                                className="btn btn-light"
                              >
                                {loading ? (
                                  <>
                                    {t("Wait")}{" "}
                                    <Spinner
                                      style={{
                                        fontSize: "1.5rem",
                                        marginLeft: "5px",
                                        height: "1em",
                                        width: "1em",
                                      }}
                                    />
                                  </>
                                ) : (
                                  t("Submit")
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Contact;
