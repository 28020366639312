import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };
  const currentLanguage = i18n.language;
  const oppositeLanguage = currentLanguage === "ar" ? "en" : "ar";
  const languageLabel = currentLanguage === "ar" ? t("EN") : t("AR");
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <header
      className="header_main"
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-auto d-none d-md-block">
            <Link className="logo" to="/">
              <img src="/assets/img/logo.png" alt />
            </Link>
          </div>
          <div className="col w-100 ">
            <div className="headermenu d-none d-md-block">
              <ul className="list-unstyled p-0 m-0">
                <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                  >
                    {t("Home")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={
                      location.pathname === "/contact-us" ? "active" : ""
                    }
                  >
                    {t("Contact Us")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    className={
                      location.pathname === "/about-us" ? "active" : ""
                    }
                  >
                    {t("About Us")}
                  </Link>
                </li>
                <li>
                  <div className="language_box d-xl-block d-lg-none d-md-none d-none">
                    <div className="dropdown">
                      <button
                        className="btn btn-white dropdown-toggle"
                        type="button"
                        onClick={toggleDropdown}
                        aria-expanded={dropdownOpen}
                        style={{ marginTop: "-5px" }}
                      >
                        <img
                          className="flag_imgg me-2"
                          src={
                            currentLanguage === "ar"
                              ? "assets/img/flags.png"
                              : "assets/img/flag_icons.png"
                          }
                          alt=""
                        />
                        <span>{currentLanguage === "ar" ? "AR" : "EN"}</span>
                        <img src="assets/img/arrow.png" alt="" />
                      </button>
                      {dropdownOpen && (
                        <ul className="dropdown-menu show p-0 ps-2">
                          <li className="">
                            <a
                              className="language_a dropdown-item"
                              href="#"
                              style={{ marginLeft: "-50px" }}
                              onClick={() => changeLanguage(oppositeLanguage)}
                            >
                              <img
                                src={
                                  currentLanguage === "ar"
                                    ? "assets/img/flag_icons.png"
                                    : "assets/img/flags.png"
                                }
                                alt=""
                              />{" "}
                              {languageLabel}
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <nav className="navbar bg-white d-block d-md-none w-100">
              <div className="w-100">
                <div className="row w-100 px-0 align-items-center">
                  <div className="col-6 px-0">
                    <div className="col-6">
                      <a className="navbar-brand navbar_a" href="#">
                        <img src="/assets/img/logo.png" className="w-100 h-100" alt srcSet />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 px-0 text-end">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon" />
                    </button>
                  </div>
                </div>
                <div
                  className="offcanvas offcanvas-end"
                  tabIndex={-1}
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div className="offcanvas-header">
                    <a className="navbar-brand " href="#">
                      <img src="/assets/img/logo.png" alt />
                    </a>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 mt-2">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/"
                        >
                          {t("Home")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact-us">
                          {t("Contact Us")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/about-us">
                          {t("About Us")}
                        </Link>
                      </li>
                      <li>
                        <div className="language_box d-md-none d-block position-absolute">
                          <div className="dropdown">
                            <button
                              className="btn btn-white dropdown-toggle pe-0"
                              type="button"
                              onClick={toggleDropdown}
                              aria-expanded={dropdownOpen}
                            >
                              <img
                                className="flag_imgg me-2"
                                src={
                                  currentLanguage === "ar"
                                    ? "assets/img/flags.png"
                                    : "assets/img/flag_icons.png"
                                }
                                alt=""
                              />
                              <span>
                                {currentLanguage === "ar" ? "AR" : "EN"}
                              </span>
                              <img src="assets/img/arrow.png" alt="" />
                            </button>
                            {dropdownOpen && (
                              <ul className="dropdown-menu show p-0 ps-2 h-100">
                                <li className="">
                                  <Link
                                    className="language_a dropdown-item"
                                    to="#"
                                    style={{ marginLeft: "0px" }}
                                    onClick={() =>
                                      changeLanguage(oppositeLanguage)
                                    }
                                  >
                                    <img
                                      src={
                                        currentLanguage === "ar"
                                          ? "assets/img/flag_icons.png"
                                          : "assets/img/flags.png"
                                      }
                                      alt=""
                                    />{" "}
                                    {languageLabel}
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Navbar;
