import React from "react";
import Layout from "./Layout/Layout";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("Boutique | About Us")}</title>
        </Helmet>

        <section className="about_banner_part">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12"></div>
            </div>
          </div>
        </section>
        <div className="About_section">
          <div className="about section lm-seaction-are mt-4">
            <div className="container">
              <div className="row mt-0 mt-md-0">
                <div className="col-lg-12 mt-0 mt-md-0 gap-2">
                  <div className="row mt-md-5">
                    <div className="col-lg-6">
                      <div className=" mt-md-0">
                        <div className="heading-st mt-0 mt-md-2">
                          <h5>{t("ABOUT US")}</h5>
                          <p className="mt-md-4 mt-0">
                            {t("Why do we use it?")}
                          </p>
                          <div className="line-dec" />
                        </div>
                        <p className="mt-4 line-dec-para ">
                          {t(
                            "It is a long established fact that a reader will be"
                          )}{" "}
                          <br />{" "}
                          {t(
                            "distracted by the readable content of a page when"
                          )}{" "}
                          <br />{" "}
                          {t("looking at its layout. The point of using Lorem")}{" "}
                          <br />{" "}
                          {t("Ipsum is that it has a more-or-less normal")}{" "}
                          <br />{" "}
                          {t(
                            "distribution of letters, as opposed to using 'Content"
                          )}{" "}
                          <br />{" "}
                          {t(
                            "here, content here', making it look like readable"
                          )}{" "}
                          <br /> {t("English")}.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center mt-md-3 about-us-img-wrapper">
                      <div className="about-left-image ">
                        <img src="/assets/img/aboutframe1.png" alt />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="About_details mt-3">
          <div className="about section lm-seaction-are mt-4">
            <div className="container">
              <div className="row mt-3">
                <div className="col-lg-12 mt-3 gap-2">
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="about-left-image  ">
                        <div className="row ">
                          <div className="col-5 ">
                            <img src="/assets/img/aboutframe3.png" alt srcSet />
                            <img src="/assets/img/aboutframe4.png" alt srcSet />
                          </div>
                          <div className="col-7">
                            <img src="/assets/img/aboutframe5.png" alt srcSet />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 align-self-center mt-3 ">
                      <div className="about-right-content mt-3">
                        <div className="heading-st mt-3">
                          <p className="mt-3 text-center">
                            {t("Why do we use it?")}{" "}
                          </p>
                          <div className="line-dec" />
                        </div>
                        <div className="d-flex gap-4 about_details_para">
                          <img
                            src="assets/img/circle.png"
                            alt
                            className="mt-5"
                          />
                          <p>
                            {t(
                              "It is a long established fact that a reader will be"
                            )}{" "}
                            <br />{" "}
                            {t("distracted by the readable content of a page")}{" "}
                            <br /> {t("when looking at its layout.")}
                          </p>
                        </div>
                        <div className="d-flex gap-4 about_details_para">
                          <img
                            src="assets/img/circle.png"
                            alt
                            className="mt-5"
                          />
                          <p>
                            {t(
                              "It is a long established fact that a reader will be"
                            )}{" "}
                            <br />{" "}
                            {t("distracted by the readable content of a page")}{" "}
                            <br /> {t("when looking at its layout.")}
                          </p>
                        </div>
                        <div className="d-flex gap-4 about_details_para">
                          <img
                            src="assets/img/circle.png"
                            alt
                            className="mt-5"
                          />
                          <p>
                            {t(
                              "It is a long established fact that a reader will be"
                            )}{" "}
                            <br />{" "}
                            {t("distracted by the readable content of a page")}{" "}
                            <br /> {t("when looking at its layout.")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="client_testimonial mt-3 mb-md-0 mb-4">
          <div className="col-md-12">
            <div className=" text-center">
              <h2>{t("Client Testimonial")}</h2>
            </div>
            <div className="container">
              <div className="row ">
                <div className="center ">
                  <div className="cards p-3 ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                  <div className="cards p-3 ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                  <div className="cards p-3  ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                  <div className="cards p-3  ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                  <div className="cards p-3 ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                  <div className="cards p-3  ">
                    <h2 className="mt-2">“</h2>
                    <h4>Ahmed</h4>
                    <p>
                      {t(
                        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ex molestias quaerat reiciendis reprehenderit sint inventore, nobis quos eligendi ut voluptatum consectetur repellat quasi rerum."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
};

export default About;
